import React from "react"

export default class Button extends React.Component {
	render() {
		return (
			<div className={`button ${this.props.classes || ''}`}>
				<span className="text">{this.props.children}</span>
				<span className="button-icon">
					<svg width="1em" height="1em" viewBox="0 0 32 32" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<polyline fill="none" stroke="" stroke-width="6" stroke-miterlimit="10" points="12.083,23.835 19.918,16 12.083,8.167 "/>
					</svg>
				</span>
			</div>
		)
	}
}