import React from "react";
import {
	Link
} from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentSection from "../components/content/section";
import Button from "../components/content/button";

import Chart from 'chart.js';

export default class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	}
	componentDidMount() {
		var ctx = document.getElementById('releaseSchedule');
		new Chart(ctx, {
			// The type of chart we want to create
			type: 'line',

			// The data for our dataset
			data: {
				labels: ['Day 0', 'Day 1', 'Day 15', 'Day 30', 'Day 45', 'Day 60', 'Day 75', 'Day 90'],
				datasets: [{
					label: 'Auction',
					borderColor: 'rgb(215, 118, 75)',
					backgroundColor: 'rgba(255, 255, 255, 0)',
					data: [0, 90, 90, 90, 90, 90, 90, 90],
					lineTension: 0
				}, {
					label: 'Liquidity Mining',
					backgroundColor: 'rgba(215, 118, 75, 0.5)',
					borderColor: 'rgb(215, 118, 75)',
					data: [0.00, 0.17, 2.50, 5.00, 7.5, 10.00, 10.00, 10.00],
					lineTension: 0
				}],

			},

			// Configuration options go here
			options: {
				tooltips: {
					mode: 'point',
					titleFontFamily: 'Averta',
					bodyFontFamily: 'Averta',
					backgroundColor: 'rgb(255, 255, 255)',
					titleFontColor: '#000',
					bodyFontColor: '#000',
					callbacks: {
						label: function(tooltipItem, data) {
							var label = ' ' + data.datasets[tooltipItem.datasetIndex].label || '';

							if (label) {
								label += ': ';
							}
							label += tooltipItem.yLabel + '%';
							return label;
						}
					}
				},
				legend: {
					labels: {
						fontColor: '#c8c8c8',
						fontFamily: 'Averta'
					}
				},
				scales: {
					yAxes: [{
						stacked: true,
						labelString: '%',
						ticks: {
							fontColor: '#c8c8c8',
							fontFamily: 'Averta',
							// Include a dollar sign in the ticks
							callback: function(value, index, values) {
								return value + '%';
							},
							sampleSize: 5,
							autoSkip: true,
						}
					}],
					xAxes: [{
						ticks: {
							fontColor: '#c8c8c8',
							fontFamily: 'Averta'
						}
					}]
				}
			}
		});
	}
	render() {
		return (
			<Layout>
				<SEO title="Ducat - Adaptive digital currency done right" />
				<div className="hero">
					<div className="container">
						<div className="row">
							<div className="col-8">
								<h1>Adaptive money with fair distribution</h1>
								<p>Imagine Ampleforth, but 50% of the supply isn't held by billionaires.</p>
								<Link to="/learn">
									<Button>Learn The Basics</Button>
								</Link>
								<br />
								<Link>
									<Button>Buy DUCAT</Button>
								</Link>
								<br />
								<Link to="/stake">
									<Button>Stake</Button>
								</Link>
							</div>
							<div className="col hidden-small">
								<img src="https://i.imgur.com/csobDPr.png" className="img-fluid" alt="Friedrich August Hayek" />
							</div>
						</div>
					</div>
				</div>
				<ContentSection title="What is Ducat" classes="first">
					<p>The Ducat is an adaptive digital currency that adjusts its supply algorithmically every day based on market conditions.</p>
					<p>The concept of a self-governing Ducat was first introduced in 1976 by Noble prize winning economist Friedrich August Hayek as a way to denationalize money, as he deemed governments unfit to be in charge of printing and controlling money supply.</p>
					<p>One of Hayek's beliefs was also that if a currency like Ducat was ever to take off, governments would fight to take it down, which is why we've created Ducat the way it is - fairly distributed, fully autonomous and unstoppable.</p>
				</ContentSection>
				<ContentSection title="Why Ducat">
					<p><b>We believe most experiments to create an adaptive currency have already failed before they got started.</b></p>
					<p><b>If the idea is to denationalize currency, we can't have a group of founders hold 25%, their VCs another 21.8%, 43.2% in vague project treasuries and only 10% left to the real users paying for billionaires to be able to dump on them.</b></p>
					<p>Which is why Ducat is following the fairest possible distribution.</p>
					<p>90% of all tokens will be sold on Gnosis Protocol bonding curve offering and 10% is reserved for liquidity mining that will launch on Uniswap shortly after the auction.</p>
					<p>The only Ducats the team, VCs or anyone really, will ever hold, need to be bought at the auction conducted on Gnosis Protocol or bought on the open market post-auction.</p>
					<p>This is the only fair distribution an adaptive currency can have, and the distribution it should have.</p>
					<p><b>Ducat is adaptive digital currency done right.</b></p>

				</ContentSection>
				<ContentSection title="Release Schedule" classes="dark">
					<p>Another very important factor for wide-spread adoption is the token release schedule. While most experiments have chosen to start with massive supplies and locking up half of it for years to come, not Ducat, and here's why.</p>
		            <p>Adaptive currency goes through rebases, which affect everyone holding the tokens, including the locked supply, essentially manipulating the amount of rebases with limited supply and getting a freeroll on locked supply.</p>
		            <p>Ducat is launched with a reasonably small starting supply, fair auction and outside of liquidity mining, the supply is 100% in circulation from day one and no team members, VCs or treasuries are getting a freeroll to later dump on the community.</p>
		            <p>Auction is followed by liquidity mining on Uniswap that runs for 60 days and will distribute all of the remaining Ducats. After that, what you see is what is there, no more tokens will ever be released or minted to dillute value of holdings.</p>
					<canvas id="releaseSchedule" width="1080px" height="480px" />
				</ContentSection>
				<ContentSection title="The DUCAT Token">
					<div className="row">
						<div className="col-6 statistic">
							<div className="label">Initial Total Supply</div>
							<div className="value">1,000,000 DUCAT</div>
						</div>
						<div className="col-6 statistic">
							<div className="label">Day 1 Circulating Supply</div>
							<div className="value">900,000 DUCAT</div>
						</div>
						<div className="col-6 statistic">
							<div className="label">Initial Price</div>
							<div className="value">$1.00 USD</div>
						</div>
						<div className="col-6 statistic">
							<div className="label">Rebase Target Price</div>
							<div className="value">$1.00 USD</div>
						</div>						
					</div>
				</ContentSection>
				<ContentSection title="Roadmap" classes="dark">
					<table class="roadmap">
			            <tbody>
			              <tr class="date">
			                <th scope="row"><span>■</span> August 14th, 2020</th>
			                <td>DUCAT Auction on Gnosis</td>
			              </tr>
			              <tr class="description">
			                <td colspan="2">90% of the total supply will be distributed via a bonding curve offering on Gnosis Protocol.<br /><br />
			                  <Link>
			                  	<Button classes="dark">Read How To Participate</Button>
			                  </Link>
			              	</td>
			              </tr>
			              <tr class="date">
			                <th scope="row"><span>■</span> August 21st, 2020</th>
			                <td>DUCAT Supernova Begins</td>
			              </tr>
			              <tr class="description">
			                <td colspan="2">The liquidity mining on Uniswap begins, codename Supernova. Running for two months and distributing the remaining 10% of the supply.</td>
			              </tr>
			              <tr class="date">
			                <th scope="row"><span>■</span> October 21st, 2020</th>
			                <td>DUCAT Supernova Ends</td>
			              </tr>
			              <tr class="description">
			                <td colspan="2">The liquidity mining on Uniswap ends and DUCAT total supply and circulating supply reach equilibrium. Supply will never be dilluted by any release in the future.</td>
			              </tr>
			              <tr class="date">
			                <th scope="row"><span>■</span> Q4 2020</th>
			                <td>Ducat Foundation DAO</td>
			              </tr>
			              <tr class="description">
			                <td colspan="2">In Q4, DUCAT becomes a governance token for Ducat Foundation DAO.</td>
			              </tr>

			            </tbody>
			        </table>
				</ContentSection>
				<ContentSection title="The Team">
					<p><b>Because DUCAT is an attempt to completely denationalize and decentralize currency, the team is remaining pseudo-anonymous.</b></p>
		          <br/>
		          <p>Reasons why the founding team will remain anonymous:</p>
		          <p><span>■</span> For the experiment to be unstoppable, no single person or group should be an attack vector.</p>
		          <p><span>■</span> Ducat is the most fairly distributed adaptive digital currency with no pre-mine or other type of team allocation.</p>
		          <p><span>■</span> Quoting Thorchain founders, "Figureheads, personalities and founders undermine a project’s ability to decentralise. Transparency is demonstrated in other facets (treasury, code, research)."</p>
		          <p><span>■</span> We believe DUCAT will have more potential when we completely exclude ourselves from the story and launch the DAO.</p>
		          <br/>
		          <p><b>With a completely fair distribution and no freerolled team tokens, the team will push and assist with listings on exchanges, integrations and improvements of protocol, but expect community support.</b></p>
		          <p>All project discussion happens on Telegram.</p>
		          <br />
		          <a href="https://t.me/ducatofficial" rel="noopener noreferrer" target="_blank">
		          	<Button>Join Ducat's Telegram</Button>
		          </a>					
				</ContentSection>
			</Layout>
		)
	}
}